<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-col class="d-none d-lg-flex p-0" lg="8" md="6">
        <SplashScreen />
      </b-col>
      <!-- Registration User-->
      <b-col class="d-flex flex-wrap align-items-center auth-bg px-2 p-lg-5" lg="4">
        <b-col class="px-xl-2 mx-auto" lg="12" md="6" sm="8">
          <b-card-title class="font-weight-bold mb-1" title-tag="h3">
            {{ $t('registration.registration_user.welcome') }} <br />
            <span class="h6"> {{ $t('registration.registration_user.please') }} </span>
          </b-card-title>

          <!-- form -->
          <p>{{ $t('registration.registration_user.organization') }} : {{ organization }}</p>
          <validation-observer ref="registrationValidation" #default="{ validate }">
            <b-form class="auth-login-form mt-2" @submit.prevent="validate().then(submitCredentials)">
              <!-- email -->
              <app-input id="registration-email" v-model="credentials.email" :label="$t('registration.registration_user.email')" rules="required|email" disabled />

              <!-- gender -->
              <enum-select id="sexType" v-model="credentials.sex" :label="$t('registration.registration_user.gender')" enum-class="SexType" required />

              <!-- first name -->
              <app-input
                id="registration-first_name"
                v-model="credentials.firstName"
                :label="$t('registration.registration_user.first_name')"
                rules="required"
              />

              <!-- last name -->
              <app-input id="registration-last_name" v-model="credentials.lastName" :label="$t('registration.registration_user.last_name')" required />

              <!-- adresse -->
              <app-input id="registration-address" v-model="credentials.address" :label="$t('registration.registration_user.address')" required />

              <!-- adresse complementaire-->
              <app-input
                id="registration-compAddress"
                v-model="credentials.complementaryAddress"
                :label="$t('registration.registration_user.complementary_address')"
              />

              <b-row class="d-flex flex-wrap">
                <b-col>
                  <!-- postal code -->
                  <app-input id="registration-postalCode" v-model="credentials.postalCode" :label="$t('registration.registration_user.postal_code')" required />
                </b-col>

                <b-col>
                  <!-- city -->
                  <app-input id="registration-city" v-model="credentials.city" :label="$t('registration.registration_user.city')" required />
                </b-col>
              </b-row>

              <!-- country -->
              <enum-select
                id="country"
                v-model="credentials.countryCode"
                :label="$t('registration.registration_user.country')"
                enum-class="CountryCode"
                text="text"
                required
              />

              <!-- password -->
              <app-input
                id="registration-password"
                v-model="credentials.password"
                :label="$t('registration.registration_user.password')"
                class="form-control-merge"
                name="registration-password"
                placeholder="············"
                rules="required|password"
                type="password"
              />

              <!-- confirm password -->
              <app-input
                id="registration-passwordConf"
                v-model="credentials.passwordConfirmation"
                :label="$t('registration.registration_user.confirm_password')"
                :state="credentials.passwordConfirmation !== credentials.password ? false : null"
                class="form-control-merge"
                name="registration-passwordConf"
                placeholder="············"
                rules="required|password:@confirm"
                type="password"
              />

              <!-- submit buttons -->
              <b-button block type="submit">
                {{ $t('action.sign_in') }}
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <div>
              <span>{{ $t('login.already_have_account') }}</span>
              <b-link :to="{ name: 'login', query: { token: $route.query.token || null } }">
                <span> {{ $t('login.log_in_instead') }}</span>
              </b-link>
            </div>
            <div>
              <span>{{ $t('login.have_question') }}</span>
              <b-link :to="{ name: 'contact', query: { token: $route.query.token || null} }">
                <span> {{ $t('login.contact_us') }}</span>
              </b-link>
            </div>
          </b-card-text>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

import { mapState } from 'vuex'
import SplashScreen from '@/views/base/SplashScreen.vue'

import { postRegisterUserRequest } from '@/request/authApi/requests/apiRequests'

export default {
  name: 'RegistrationUser',

  components: {
    SplashScreen,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      credentials: {
        email: '',
        address: '',
        complementaryAddress: '',
        postalCode: '',
        city: '',
        countryCode: '',
        firstName: '',
        lastName: '',
        sex: '',
        password: '',
        passwordConfirmation: '',
        redirectUrl: '',
        organizationToken: '',
      },
      organization: '',
      sideImg: require('@/assets/images/pages/login/airplane.jpg'),
      logoFull: require('@/assets/images/logo/logo.svg'),
    }
  },
  computed: {
    ...mapState('appConfig', ['layout']),

    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (this.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login/airplane-dark.jpg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  mounted() {
    this.credentials.email = this.$route.query.email
    this.organization = this.$route.query.organizationName
  },
  methods: {
    submitCredentials() {
      return this.$refs.registrationValidation.validate().then(success => {
        if (success) {
          this.credentials.redirectUrl = `${window.location.origin}/registration-user-valid`
          this.credentials.organizationToken = this.$route.query.token
          postRegisterUserRequest(this.credentials).then(() => {
            this.$router.push({ name: 'registration-user-validation' })
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
